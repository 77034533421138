.main-navigation_menu-btn {
    width: 3rem;
    height: 3rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
  }
  
  .main-navigation_menu-btn span {
    display: block;
    width: 3rem;
    height: 2.5px;
    background: white;
  }
  
  .main-navigation_title {
    color: white;
  }
  
  .main-navigation_title a {
    text-decoration: none;
    color: white;
    font-size: 2em;
  }
  
  .main-navigation_header-nav {
    display: none;
  }
  
  .main-navigation_drawer-nav {
    height: 40%;
    /* background-color: red; */
  }
  
  @media (min-width: 768px) {
    .main-navigation_menu-btn {
      display: none;
    }
  
    .main-navigation_header-nav {
      display: block;
    }
  }