.profile-bottom-drawer{
    position: fixed;
    left: 0;
    z-index: 100;
    margin-top: 3em;
    width: 97.8%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.profile-bottom-drawer h1{
    color: #408db1;
    text-align: center;
    font-size: 1rem;
}