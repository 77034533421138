.side-drawer{
    position: fixed;
    /* left: 0;
    top: 0; */
    margin-top: 5em;
    z-index: 100;
    height: 80em;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.side-drawer h1{
    color: #408db1;
    text-align: center;
    font-size: 2rem;
}