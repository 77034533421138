.header-container {
    width: 100%;
    height: 30vw;
    background-image: url("../../../shared/resources/images/outdoor.jpeg");
    margin-top: 4.5rem;
    border: 1px solid;
    background-size: 100% 100%;
    text-align: center;
}

.header-container h1 {
    position: inherit;
    margin-top: 2.5em;
    font-size: 5vw;
    color: white;
    text-shadow: 2px red;text-shadow: 4px 4px #5CA6FD;
}

@media (max-width: 768px) {
    .header-image img {
        margin-top: 5rem;
    }
}