li.savedPlacesItem-content{
  /* width: 100%; */
  display: inline-block;
  text-align: center;
  padding: 24px;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid black;
}

.favPlace_item_image img {
  /* Add styles for the restaurant image */
  width: 100%;
  height: 250px; /* Adjust the image height as needed */
  object-fit: cover;
}

.place_item_info {
  /* Add styles for the restaurant info section */
  padding: 10px;
}

.place_item_actions {
  /* Add styles for the action buttons */
  display: flex;
  justify-content: center;
  padding: 10px;
}

@media (max-width: 768px) {
  li.savedPlacesItem-content{
      width: 80%;
      
  }
}