/* Box.css */
.box {
  width: 25%; 
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
  text-decoration: none;
  color: black;
  /* margin-right: 50px; */

}

.box img {
  width: 100%;
  height: auto;
}

.box p {
  font-size: 1vw;
}

@media (max-width: 768px) {
  .box {
    /* Set width for mobile layout (2 boxes per row) */
    width: 50%; 
  }

  .box h3 {
    font-size: 4vw;
  }

  .box p {
    font-size: 2vw;
  }
}