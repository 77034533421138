.modal {
    z-index: 100;
    position: fixed;
    top: 22vh;
    left: 10%;
    width: 80%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
  }
  
  .modal__header {
    width: 100%;
    padding: 1rem 0.5rem;
    background: #2a006e;
    color: white;
  }
  
  .modal__header h2 {
    margin: 0.5rem;
  }
  
  .modal__content {
    padding: 1rem 0.5rem;
  }
  
  .modal__footer {
    padding: 1rem 0.5rem;
  }
  
  @media (min-width: 768px) {
    .modal {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }
  
  .modal-enter {
    transform: translateY(-10rem);
    opacity: 0;
  }
  
  .modal-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .modal-exit {
    transform: translateY(0);
    opacity: 1;
  }
  
  .modal-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 200ms;
  }


  /* --------------------------   Moal map styles ------------------------ */

.place-item_info {
  padding: 1rem;
  text-align: center;
}

.place-item_info h2,
.place-item_info h3,
.place-item_info p {
  margin: 0 0 0.5rem 0;
}

.place-item_actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .place-item_imge {
    height: 20rem;
  } 
}