/* --------------------------   component items styles ------------------------ */

.place-flex-item {
    /* Add flex styles for each restaurant item */
    width: calc(25% - 10px); /* 25% for each item with 10px margin */
    margin: 5px; /* Add a small margin between items */
  
    /* Add media query for smaller screens */
    @media (max-width: 768px) {
      width: calc(50% - 10px); /* 50% for each item with 10px margin */
    }
  }
  
  .place-card {
    /* Add styles for the card container */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
  }
  
  .place-flex--item_image img {
    /* Add styles for the restaurant image */
    width: 100%;
    height: 250px; /* Adjust the image height as needed */
    object-fit: cover;
  }
  
  .place-item_info {
    /* Add styles for the restaurant info section */
    padding: 10px;
  }
  
  .place-item_actions {
    /* Add styles for the action buttons */
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  