.header-image img {
    width: 100%;
    height: 40vw;
    position: relative;
    margin-top: 6em;
}

@media (max-width: 768px) {
    .header-image img {
        /* margin-top: 16em; */
    }
}