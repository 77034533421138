.place-container {
    /* background-color:aqua; */
    margin-top: 100px;
    /* float: left; */
}

.place-heading {
    display: grid;
    grid-template-columns: 90% 10%;
}

.place-heading button {
    height: 40px;
    width: 10vw;    
}

@media (max-width: 768px) {
    .place-heading {
        grid-template-columns: 70% 30%;
    }
    .place-heading h1 {
        font-size: 5vw;
    }
    .place-heading button  {
        width: 25vw;
    }
  }