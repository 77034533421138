/* Header.css */
.weather {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid black;
  border-radius: 8px; 
  text-align: center;
  height: 140px;
}

.weather-box {
  /* background-color: #f0f0f0; */
  /* padding: 10px; */
  /* border-radius: 8px; */
  /* margin-top: -23rem; */
  /* text-align: center; */
  /* position: relative; */
}

@media (max-width: 768px) {
  .weather-box {
    /* position: absolute; */
    /* margin-top: -12.5rem; */
    /* font-size: .8rem; */
    
  }
}